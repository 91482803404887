import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { PageTitle } from '../components/Common/PageTitle';
import AktionenList from '../components/Aktionen/List';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

const AktionenPage = ({ data, location, locale }) => {
  const { siteMetadata } = data.site;

  const { title, icon, metadata, opengraph } = data.page;
  const filteredAktionen = data.aktionen.edges.filter(({ node }) => {
    if (node.expires) {
      const expirationDate = new Date(node.expires);
      const now = new Date();
      const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
      return expirationDate.getTime() > startOfToday.getTime();
    }
    return true;
  });

  return (
    <DefaultLayout>
      <div className={'wrap'}>
        <PageTitle title={title} icon={icon} />
        <AktionenList entries={filteredAktionen} />
        {/* <Awards /> */}
      </div>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
    </DefaultLayout>
  );
};

export default AktionenPage;

export const AktionenPageQuery = graphql`
  query Aktionen($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionAktionen(lang: { eq: $language }) {
      title
      icon
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    aktionen: allCockpitAktionen(
      sort: { fields: [order], order: ASC }
      filter: { published: { eq: true }, lang: { eq: $language } }
    ) {
      edges {
        node {
          id
          title
          externalLink
          expires
          image {
            childImageSharp {
              gatsbyImageData(height: 450, aspectRatio: 1.333, quality: 100)
            }
          }
        }
      }
    }
  }
`;
